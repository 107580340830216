import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Device} from '@twilio/voice-sdk';
import IOController from '../controllers/IOController';
import {ScreenConsumer} from './Screen';
import {AppConsumer} from './App';
import {DepartmentsConsumer} from './Departments';
import {TemplateConsumer} from './Template';
import VideoChat from '../models/VideoChat';
import {getBrowserOs} from '../utils/browserFeatures';
import securityStates from '../utils/securityStates';
import {
    getCountryCodeWithoutCountry,
    getPhoneWithoutCode,
} from '../utils/phoneNumberUtils';
import MobileDetect from 'mobile-detect';
import NewMessage from '../assets/new_message.mp3';
import _ from 'lodash';
import AzureSpeechRecognizer from '../utils/AzureSpeechRecognizer';
import ParticipantPhrases from '../utils/ParticipantPhrases';

let twilioDevice = null;
let twilioCall = null;

const newQrChatEventAudio = new Audio();

const RECONNECTION_ERROR_CODE = 417;
const REQUESTED_OPERATOR_IS_OFFLINE = 418;
const HAS_NO_ADDITIONAL_MINUTES = 419;
const QR_INTERCOM_DEPARTMENT_NOT_FOUND = 420;
const CALL_TO_PHONE = 421;

const NO_QR_CODE_DEPARTMENTS_CHOSEN = 'nodepartments';

const RECOGNITION_PHRASE_TTL = 8000;

const SNAPSHOT_DEFAULT_STATE = {
    isSnapshotShown: false,
    snapshotDrawings: {},
    snapshot: {},
    snapshotSize: null,
};

const ConsultationContext = React.createContext('consultation');

const baseState = {
    requested: false,
    paused: false,
    consultant: null,
    privateRoomId: null,
    videoChat: null,
    localStream: null,
    remoteStream: null,
    consultationRequest: null,
    consultationRequestError: false,
    privateChatRequest: null,
    consultationRequestState: 'initial',
    consultationState: 'initial',
    stateBeforeChatOpened: 'initial',
    chatState: 'initial',
    chatDepartmentId: NO_QR_CODE_DEPARTMENTS_CHOSEN,
    qrCodeChats: {},
    requestedOperatorName: '',
    consultationError: '',
    remoteVideoOn: true,
    videoAllowed: true,
    didSendPhoneNumber: false,
    deleteCodeAfterConsultation: false,
    consultationType: 'audiovideo',
    consultationRequestProgress: false,
    phoneConsultationOnPCRequested: false,
    isConsultantHasAccessToRequestedDepartment: true,
    qrIntercomNumberNotFound: false,
    recognitionEnabled: false,
    recognitionLanguages: {
        operatorLanguage: null,
        screenLanguage: null,
    },
    recognitionPhrases: [],
    ...SNAPSHOT_DEFAULT_STATE,
    isTwilioDeviceInitiated: false,
    phoneNumberSettings: {},
    inWaitingToCallState: false,
    consultationRequestRemoved: false,
};

class ConsultationContextProvider extends Component {
    constructor(props) {
        super(props);
        const mobileDetect = new MobileDetect(window.navigator.userAgent);
        this.isMobile = !!mobileDetect.mobile();
        // Safari does not allow play audio before user interaction.
        if (mobileDetect.userAgent() === 'Safari') {
            document.addEventListener('touchstart', this.onSafariTouchStart);
        } else {
            newQrChatEventAudio.src = NewMessage;
        }

        this.phraseDeletionTimeout = null;
        this.participantPhrases = new ParticipantPhrases(
            RECOGNITION_PHRASE_TTL
        );
    }

    static propTypes = {
        screenState: PropTypes.object.isRequired,
        screenActions: PropTypes.object.isRequired,
        appState: PropTypes.object.isRequired,
        appActions: PropTypes.object.isRequired,
        departmentsState: PropTypes.object.isRequired,
        hasSecurityLock: PropTypes.bool,
        isBlocked: PropTypes.bool,
    };

    state = {
        iceServers: [],
        ...baseState,
        modalsState: {
            terms: {
                isOpened: false,
                isConfirmed: false,
            },
            devices: {
                isOpened: false,
                isConfirmed: false,
            },
            onConfirm: null,
        },
    };

    actions = {
        getInitialChatSettings: this.getInitialChatSettings.bind(this),
        stopConsultationRequest: this.stopConsultationRequest.bind(this),
        sendConsultationRequestSignal:
            this.sendConsultationRequestSignal.bind(this),
        sendFeedback: this.sendFeedback.bind(this),
        requestConsultation: this.requestConsultation.bind(this),
        requestConsultationWithType:
            this.requestConsultationWithType.bind(this),
        requestConsultationWithDepartmentNumber:
            this.requestConsultationWithDepartmentNumber.bind(this),
        requestConsultationWithDepartmentNumberWithType:
            this.requestConsultationWithDepartmentNumberWithType.bind(this),
        requestConsultationWithDepartmentId:
            this.requestConsultationWithDepartmentId.bind(this),
        openChat: this.openChat.bind(this),
        closeChat: this.closeChat.bind(this),
        backFromChat: this.backFromChat.bind(this),
        backFromAIChat: this.backFromAIChat.bind(this),
        requestChat: this.requestChat.bind(this),
        requestAIChat: this.requestAIChat.bind(this),
        getQrChatMessages: this.getQrChatMessages.bind(this),
        getUserChats: this.getUserChats.bind(this),
        sendQrChatMessage: this.sendQrChatMessage.bind(this),
        sendQrChatMessageFromConsultation:
            this.sendQrChatMessageFromConsultation.bind(this),
        sendConsultationRequestSignalToOnlineMembers:
            this.sendConsultationRequestSignalToOnlineMembers.bind(this),
        readQrCodeChat: this.readQrCodeChat.bind(this),
        sendOperatorIsTyping: this.sendOperatorIsTyping.bind(this),
        requestConsultationFromChat:
            this.requestConsultationFromChat.bind(this),
        requestPhoneConsultation: this.requestPhoneConsultation.bind(this),
        removePhoneConsultationOnPC:
            this.removePhoneConsultationOnPC.bind(this),
        toSendingPhoneNumber: this.toSendingPhoneNumber.bind(this),
        toChooseDepartmentsOrCancel:
            this.toChooseDepartmentsOrCancel.bind(this),
        cancelLeavingPhoneNumber: this.toStartState.bind(this),
        sendCallRequest: this.sendCallRequest.bind(this),
        toggleVoice: this.toggleVoice.bind(this),
        toggleVideo: this.toggleVideo.bind(this),
        closeConsultation: this.closeConsultation.bind(this),
        stopConsultation: this.stopConsultation.bind(this),
        switchCamera: this.switchCamera.bind(this),
        toStartState: this.toStartState.bind(this),
        createSnapshot: this.createSnapshot.bind(this),
        handleLocalSnapshotClose: this.handleLocalSnapshotClose.bind(this),
        closeTermsModal: this.closeTermsModal.bind(this),
        closeDevicesModal: this.closeDevicesModal.bind(this),
        removeQrIntercomNumberNotFoundState:
            this.removeQrIntercomNumberNotFoundState.bind(this),
        onPhotoDone: this.onPhotoDone.bind(this),
        setInWaitingCallState: this.setInWaitingCallState.bind(this),
    };

    candidateQueue = [];

    componentDidMount() {
        this.setPhraseDeletionTimeout();
    }

    componentDidUpdate(prevProps) {
        const {videoChat, consultationRequestState} = this.state;

        if (
            this.isUserRegistered(prevProps) ||
            this.areDepartmentsChanged(prevProps) ||
            this.isTemplateAttached(prevProps)
        ) {
            this.getUserChats();
        }
        if (this.isTemplateDetached(prevProps)) {
            this.setState({qrCodeChats: {}});
        }

        if (this.gotScreenUser(prevProps)) {
            this.getIceServers();

            if (prevProps.screenState.user || this.isMiniWebsiteState()) {
                if (!videoChat) {
                    this.stopConsultationRequest(true, false);
                }
            }
        }

        if (this.needRequestConsultation(prevProps)) {
            return this.toStartState();
        }

        if (this.adminRemovedQrCodeAcces(prevProps)) {
            return this.stopAllActivity();
        }

        if (
            this.roomBecomeUnavailable(prevProps) &&
            consultationRequestState === 'requestSended' &&
            (this.isInQrState() || this.isMiniWebsiteState())
        ) {
            return this.stopConsultationRequest();
        }

        if (this.allDepartmentsWereDetached(prevProps) && !videoChat) {
            return this.stopConsultationRequest();
        }

        if (
            this.adminRemovedDepartmentsAccess(prevProps) &&
            consultationRequestState === 'chooseDepartments' &&
            (this.isInQrState() || this.isMiniWebsiteState())
        ) {
            return this.toStartState();
        }

        if (
            !prevProps.isInitiallyConnected &&
            this.props.isInitiallyConnected
        ) {
            this.subscribeToSocketEvents();
        }

        if (
            !prevProps.appState.disconnectAfterConsultation &&
            this.props.appState.disconnectAfterConsultation
        ) {
            if (videoChat) {
                this.setState({deleteCodeAfterConsultation: true});
            } else {
                this.props.appActions.setAppError(
                    'Sorry, your link is no longer valid.'
                );
            }
        }

        if (prevProps.connected && !this.props.connected) {
            this.stopConsultationRequest();

            if (videoChat) {
                videoChat.stop();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('touchstart', this.onSafariTouchStart);

        if (this.phraseDeletionTimeout) {
            clearTimeout(this.phraseDeletionTimeout);
        }

        AzureSpeechRecognizer.stopAll(
            (workedBefore = true) => {
                if (!workedBefore) {
                    return;
                }
                IOController.socket.emit('azureStatistic_writeStatistic', {
                    type: 'end',
                    recognizerType: 'speech',
                });
            },
            (workedBefore = true) => {
                if (!workedBefore) {
                    return;
                }
                IOController.socket.emit('azureStatistic_writeStatistic', {
                    type: 'end',
                    recognizerType: 'translation',
                });
            }
        );
        this.participantPhrases.clear();
    }

    subscribeToSocketEvents() {
        IOController.socket.on('qrCode_deleted', this.onDeleteQrCode);

        IOController.socket.on(
            'room_requestPrivateChat',
            this.onRequestPrivateChat
        );
        IOController.socket.on('room_userLeft', this.onUserLeft);
        IOController.socket.on('room_forwardInfo', this.onRoomForwardInfo);
        IOController.socket.on(
            'room_removePrivateChat',
            this.onRoomRemovePrivateChat
        );

        IOController.socket.on('videoChat_offer_p2p', this.onVideoChatOfferP2P);
        IOController.socket.on(
            'videoChat_iceCandidate_p2p',
            this.onRemoteIceCandidate
        );
        IOController.socket.on('videoChat_pause_p2p', this.onPauseP2P);
        IOController.socket.on('videoChat_play_p2p', this.onPlayP2P);
        IOController.socket.on(
            'videoChat_videoStateChanged_p2p',
            this.onRemoteVideoStateChanged
        );

        IOController.socket.on(
            'room_updateConsultationRequest',
            this.onUpdateConsultationRequest
        );

        IOController.socket.on(
            'qrChat_chatAssigned',
            this.changeChatAssigned.bind(this, true)
        );
        IOController.socket.on(
            'qrChat_chatIntercepted',
            this.onChatIntercepted.bind(this)
        );
        IOController.socket.on(
            'qrChat_chatClosed',
            this.changeChatAssigned.bind(this, false)
        );
        IOController.socket.on(
            'qrChat_newMessage',
            this.onQrCodeChatNewMessage.bind(this)
        );
        IOController.socket.on(
            'qrChat_userIsTyping',
            this.onOperatorIsTyping.bind(this)
        );
        IOController.socket.on(
            'qrChat_newChat',
            this.onNewQrCodeChat.bind(this)
        );
        IOController.socket.on(
            'qrChat_chatRemoved',
            this.onChatRemoved.bind(this)
        );

        IOController.socket.on(
            'speechRecognition_start',
            this.onStartRecognition
        );
        IOController.socket.on(
            'speechRecognition_stop',
            this.onStopRecognition
        );
        IOController.socket.on(
            'speechRecognition_sendText',
            this.onOperatorPhrase
        );
    }

    isUserRegistered(prevProps) {
        const {isUserRegistered: prevIsUserRegistered} = prevProps;
        const {isUserRegistered} = this.props;
        return !prevIsUserRegistered && isUserRegistered;
    }

    areDepartmentsChanged(prevProps) {
        const {departments: prevDepartments} = prevProps.departmentsState;
        const {departments} = this.props.departmentsState;
        return prevDepartments.length !== departments.length;
    }

    isTemplateAttached(prevProps) {
        const {template: prevTemplate} = prevProps.templateState;
        const {template} = this.props.templateState;
        return template && !prevTemplate;
    }

    isTemplateDetached(prevProps) {
        const {template: prevTemplate} = prevProps.templateState;
        const {template} = this.props.templateState;
        return !template && prevTemplate;
    }

    onSafariTouchStart() {
        document.removeEventListener('touchstart', this.onSafariTouchStart);
        newQrChatEventAudio.src = NewMessage;
    }

    playNewQrChatEvent() {
        if (!document.hidden) {
            newQrChatEventAudio.play();
        }
    }

    _changeChat(qrCodeChatId, chatValues) {
        const {qrCodeChats} = this.state;
        const [newChatKey, newChatValue] = Object.entries(qrCodeChats).find(
            ([key, val]) => val.id === qrCodeChatId
        );
        const newChat = Object.fromEntries([
            [newChatKey, {...newChatValue, ...chatValues}],
        ]);
        this.setState({qrCodeChats: {...qrCodeChats, ...newChat}});
    }

    onChatIntercepted({qrCodeChatId, users}) {
        const [_, operator] = users;
        this._changeChat(qrCodeChatId, {operator});
    }

    onChatRemoved({qrCodeChatId}) {
        const {qrCodeChats} = this.state;
        const newQrCodeChats = {...qrCodeChats};
        const [chatKey] = Object.entries(qrCodeChats).find(
            ([key, val]) => val.id === qrCodeChatId
        );
        delete newQrCodeChats[chatKey];
        this.setState({qrCodeChats: newQrCodeChats});
    }

    changeChatAssigned(assigned, {qrCodeChatId, users}) {
        const [_, operator] = users || [];
        this._changeChat(qrCodeChatId, {operator, assigned});
    }

    onQrCodeChatNewMessage({message}) {
        const {qrCodeChats} = this.state;
        const qrCodeChatsEntries = Object.entries(qrCodeChats);
        if (!qrCodeChatsEntries.length) {
            const department = _.get(
                message,
                'chat.department.id',
                NO_QR_CODE_DEPARTMENTS_CHOSEN
            );
            this.setState(
                {
                    qrCodeChats: {
                        [department]: {
                            ...message.chat,
                            isOperatorTyping: false,
                            wasRead: message.chat.wasRead,
                            operator: message.chat.users[1],
                            assigned: true,
                            messages: [],
                        },
                    },
                },
                this.playNewQrChatEvent
            );
            return;
        }
        const [newChatKey, newChatValue] = Object.entries(qrCodeChats).find(
            ([key, val]) => val.id === message.chat.id
        );
        const newChatMessages = [...newChatValue.messages, message];
        const newChat = Object.fromEntries([
            [
                newChatKey,
                {
                    ...newChatValue,
                    messages: newChatMessages,
                    isOperatorTyping: false,
                    wasRead: message.chat.wasRead,
                },
            ],
        ]);
        this.setState(
            {qrCodeChats: {...qrCodeChats, ...newChat}},
            this.playNewQrChatEvent
        );
    }

    onNewQrCodeChat({chat}) {
        const {qrCodeChats} = this.state;
        const newQrCodeChats = {...qrCodeChats};
        const chatDepartmentId = _.get(
            chat,
            'department.id',
            NO_QR_CODE_DEPARTMENTS_CHOSEN
        );
        const [user, operator] = chat.users;
        newQrCodeChats[chatDepartmentId] = {
            isOperatorTyping: false,
            wasRead: false,
            id: chat.id,
            assigned: true,
            messages: [chat.lastMessage],
            operator,
        };
        this.setState({qrCodeChats: newQrCodeChats}, this.playNewQrChatEvent);
    }

    onOperatorIsTyping({
        departmentId,
        name: operatorName,
        isTyping: isOperatorTyping,
    }) {
        const {qrCodeChats} = this.state;
        const department = departmentId || NO_QR_CODE_DEPARTMENTS_CHOSEN;
        if (
            qrCodeChats[department] &&
            qrCodeChats[department].isOperatorTyping !== isOperatorTyping
        ) {
            const newChat = {
                ...qrCodeChats[department],
                isOperatorTyping,
                operatorName,
            };
            this.setState({
                qrCodeChats: {...qrCodeChats, [department]: newChat},
            });
        }
    }

    sendOperatorIsTyping(qrCodeChatId, isTyping) {
        IOController.socket.emit('qrChat_userIsTyping', {
            qrCodeChatId,
            isTyping,
        });
    }

    isConsultationStarted() {
        return this.state.consultationState === 'started';
    }

    roomBecomeUnavailable(prevProps) {
        const room = this.props.screenState.room;
        const oldRoom = prevProps.screenState.room;
        if (!room || !oldRoom) {
            return false;
        }
        return oldRoom.hasAvailableMembers && !room.hasAvailableMembers;
    }

    adminRemovedDepartmentsAccess(prevProps) {
        const room = this.props.screenState.room;
        const oldRoom = prevProps.screenState.room;
        return (
            room &&
            oldRoom &&
            oldRoom.roomInfo.allow_multiple_tap_me_buttons &&
            !room.roomInfo.allow_multiple_tap_me_buttons
        );
    }

    adminRemovedQrCodeAcces(prevProps) {
        const room = this.props.screenState.room;
        const oldRoom = prevProps.screenState.room;
        const newRoomData = _.get(room, 'roomInfo', {});
        const isBlankRoomInfo = Object.values(newRoomData).every(v => {
            if (Array.isArray(v)) return !Boolean(v.length);
            return !Boolean(v);
        });
        return (
            room &&
            oldRoom &&
            oldRoom.roomInfo.allow_qr_codes &&
            !room.roomInfo.allow_qr_codes &&
            !isBlankRoomInfo
        );
    }

    gotScreenUser(prevProps) {
        return prevProps.screenState.user !== this.props.screenState.user;
    }

    gotDepartments(prevProps) {
        const oldGotDepartments = prevProps.departmentsState.gotDepartments;
        const newDepartments = this.props.departmentsState.gotDepartments;
        return !oldGotDepartments && newDepartments;
    }

    gotUnlocked(prevProps) {
        const oldState = prevProps.appState.securityLockValidationResult;
        const newState = this.props.appState.securityLockValidationResult;
        return (
            oldState !== securityStates.UNLOCKED &&
            newState === securityStates.UNLOCKED
        );
    }

    needRequestConsultation = prevProps => {
        if (this.isInQrState()) {
            if (
                !this.props.appState.hasSecurityLock &&
                this.gotDepartments(prevProps)
            ) {
                return true;
            }
            if (
                this.gotUnlocked(prevProps) &&
                this.props.departmentsState.gotDepartments
            ) {
                return true;
            }
        }
        return false;
    };

    allDepartmentsBecomeUnavailable(prevProps) {
        const {allowMultipleTapMeButtons, hasAvailableDepartments} =
            this.props.departmentsState;
        return (
            allowMultipleTapMeButtons &&
            !hasAvailableDepartments &&
            prevProps.departmentsState.hasAvailableDepartments
        );
    }

    allDepartmentsWereDetached(prevProps) {
        const newNumberOfDepartments =
            this.props.departmentsState.departments.length;
        const oldNumberOfDepartments =
            prevProps.departmentsState.departments.length;
        return !newNumberOfDepartments && oldNumberOfDepartments;
    }

    isInQrState() {
        return this.props.appState.appState === 'qr';
    }

    isMiniWebsiteState() {
        return this.props.appState.appState === 'miniWebsite';
    }

    clearConsultationState() {
        const {videoChat, qrCodeChats} = this.state;
        if (videoChat) {
            videoChat.stop();
        }
        this.setState({...baseState, qrCodeChats});
    }

    getIceServers() {
        IOController.socket.emit('twilio_getIceServers', {}, (err, servers) => {
            this.setState({mount: true, iceServers: servers});
        });
    }

    onPauseP2P = () => {
        this.setState({paused: true});
    };

    onPlayP2P = () => {
        this.setState({paused: false});
    };

    onRemoteVideoStateChanged = state => {
        const {videoEnabled: remoteVideoOn} = state;
        this.setState({remoteVideoOn});
    };

    onRoomRemovePrivateChat = () => {
        this.stopConsultationOrOpenSurveyRateChat();
    };

    stopConsultationOrOpenSurveyRateChat(error) {
        const room = this.props.screenState.room;
        const {setAppError} = this.props.appActions;
        const {
            privateChatRequest,
            videoChat,
            deleteCodeAfterConsultation,
            privateRoomId,
        } = this.state;
        if (videoChat) {
            videoChat.stop();
        }
        if (deleteCodeAfterConsultation) {
            return setAppError('Sorry, the link is no longer valid');
        }
        if (
            privateChatRequest?.allowSurveyRateChat ||
            room?.roomInfo?.allow_survey_rate_chat
        ) {
            return this.setState({
                consultationState: 'surveyRate',
                consultationError: error,
            });
        }
        this.stopConsultation(error);
    }

    stopConsultation(error, forceDisconnect = false) {
        const {setAppError} = this.props.appActions;
        const {consultationError, privateRoomId} = this.state;
        if (privateRoomId) {
            IOController.socket.emit('room_leaveRoom', {
                roomId: privateRoomId,
                forceDisconnect,
            });
        }
        this.onPhoneCallingDestroy(true);
        if (this.isInQrState() || this.isMiniWebsiteState()) {
            this.clearConsultationState();
            return this.toStartState();
        }
        setAppError(
            consultationError || error || 'The consultation is over. Thank you!'
        );
        this.clearConsultationState();
    }

    onRoomForwardInfo = data => {
        clearTimeout(this.waitConsultantTimer);
        const {
            isConsultantHasAccessToRequestedDepartment,
            newMember: consultant,
        } = data;
        this.setState({
            consultant,
            isConsultantHasAccessToRequestedDepartment,
        });
    };

    onUserLeft = data => {
        const {consultant, privateRoomId} = this.state;

        if (consultant && data.user.id === consultant.id) {
            this.waitConsultantTimer = setTimeout(() => {
                this.stopConsultationOrOpenSurveyRateChat(
                    'Consultant has left the conversation',
                    privateRoomId
                );
            }, 5000);
        }
    };

    onRequestPrivateChat = data => {
        const {consultationRequestRemoved} = this.state;
        const {user} = this.props.screenState;
        if (consultationRequestRemoved) {
            this.stopConsultation();
            this.removePrivateChat();
            IOController.socket.emit('room_updateBusyState', {
                busyState: false,
            });
            return;
        }
        this.setState(
            {
                privateChatRequest: data,
                consultant: data.requestedUser,
                requested: true,
                privateRoomId: data.roomId,
                consultationRequest: null,
                consultationRequestState: 'initial',
                consultationState: 'started',
                inWaitingToCallState: false,
            },
            () => {
                navigator.mediaDevices
                    .enumerateDevices()
                    .then(devices => {
                        const {consultationRequestRemoved} = this.state;
                        if (consultationRequestRemoved) {
                            this.stopConsultation();
                            this.removePrivateChat();
                            IOController.socket.emit('room_updateBusyState', {
                                busyState: false,
                            });
                            return;
                        }
                        IOController.socket.emit('room_acceptPrivateChat', {
                            roomId: data.roomId,
                            id: data.requestedUser.id,
                            message: data.message,
                        });
                        IOController.socket.emit('room_add', {
                            roomIds: [data.roomId],
                            busyState: true,
                            type: user.type,
                            userId: user.userId,
                            devices,
                            appType: 'qr_screen',
                            companyId: _.get(
                                this.props.screenState,
                                'room.companyId',
                                null
                            ),
                        });
                    })
                    .catch(e => console.error(e));
            }
        );
    };

    onRemoteIceCandidate = data => {
        const {videoChat} = this.state;
        if (!videoChat || !videoChat.readyToAddCandidate) {
            return this.candidateQueue.push(data.candidate);
        }
        videoChat.addIceCandidate(data.candidate);
    };

    getInitialChatSettings() {
        const {consultationType} = this.state;
        return {
            withoutVideoTrack: consultationType === 'audio',
            voiceEnabled: true,
            videoEnabled: consultationType.includes('video'),
        };
    }

    onVideoChatOfferP2P = data => {
        const {iceServers, videoChat, consultationRequestRemoved} = this.state;
        const {screenState} = this.props;
        const forceTurnUsage = _.get(
            screenState,
            'room.roomInfo.force_turn_usage',
            false
        );
        if (consultationRequestRemoved) {
            this.stopConsultation();
            this.removePrivateChat();
            IOController.socket.emit('room_updateBusyState', {
                busyState: false,
            });
            return;
        }
        let {withoutVideoTrack, voiceEnabled, videoEnabled} =
            this.getInitialChatSettings();
        let facingMode = 'user';
        if (videoChat) {
            withoutVideoTrack = videoChat.withoutVideoTrack;
            voiceEnabled = videoChat.voiceEnabled;
            videoEnabled = videoChat.videoEnabled;
            facingMode = videoChat.facingMode;
            videoChat.stop();
        }
        const newVideoChat = new VideoChat({
            forceTurnUsage,
            offer: data.offer,
            roomId: data.roomId,
            presenterId: data.presenterId,
            iceServers: iceServers,
            onMediaError: this.onMediaError,
            onGetMediaError: this.onGetMediaError,
            onGenerateCandidate: this.onGenerateCandidate,
            onGotSdpAnswer: this.onGotSdpAnswer,
            onSnapshotCreated: this.handleSnapshotReceived,
            onDrawUpdate: this.handleSnaphotDrawUpdate,
            onSnapshotClose: () => this.setState(SNAPSHOT_DEFAULT_STATE),
            onReceiveCreateInfo: this.handleSnapshotInfo,
            onGotLocalStream: localStream => {
                const {recognitionEnabled} = this.state;
                this.setState({localStream}, () => {
                    if (recognitionEnabled) {
                        this.unpauseRecognition();
                    }
                });
            },
            onGotRemoteStream: remoteStream => this.setState({remoteStream}),
            onVideoStateChanged: remoteVideoOn => {
                this.setState({remoteVideoOn});
            },
            voiceEnabled,
            videoEnabled,
            facingMode,
            withoutVideoTrack,
            onReleaseLocalStream: () => {
                const {recognitionEnabled} = this.state;
                if (recognitionEnabled) {
                    this.pauseRecognition();
                }
                this.setState({localStream: null});
            },
        });
        if (consultationRequestRemoved) {
            newVideoChat.stop();
            this.stopConsultation();
            this.removePrivateChat();
            IOController.socket.emit('room_updateBusyState', {
                busyState: false,
            });
            return;
        }
        this.setState({paused: false, videoChat: newVideoChat}, () =>
            newVideoChat.start()
        );
    };

    onGotSdpAnswer = (sdp, roomId, presenterId) => {
        const {user} = this.props.screenState;
        const {videoChat} = this.state;

        IOController.socket.emit('videoChat_answer_p2p', {
            quality: 'high',
            userId: user.id,
            roomId: roomId,
            presenterId: presenterId,
            sdpAnswer: sdp,
            videoEnabled: videoChat.videoEnabled,
        });
        this.candidateQueue.forEach(candidate =>
            videoChat.addIceCandidate(candidate)
        );
        this.candidateQueue = [];
    };

    onGenerateCandidate = (candidate, roomId, presenterId) => {
        const {user} = this.props.screenState;
        IOController.socket.emit('videoChat_iceCandidate_p2p', {
            quality: 'high',
            userId: user.id,
            roomId: roomId,
            presenterId: presenterId,
            candidate: candidate,
        });
    };

    onMediaError = err => {
        const {consultationState} = this.state;
        const {setAppError} = this.props.appActions;
        console.log(err.message);
        IOController.socket.emit('webrtc_sendLogs', {
            logType: 'onMediaError',
            log: err.message,
        });
        if (consultationState === 'started') {
            this.removePrivateChat();
            setAppError('Sorry, your browser is not supported.');
        }
    };

    onGetMediaError = err => {
        const {consultationState} = this.state;
        const {setAppError} = this.props.appActions;
        console.log(err);
        IOController.socket.emit('webrtc_sendLogs', {
            logType: 'onGetMediaError',
            log: err.message,
        });
        if (consultationState === 'started') {
            this.removePrivateChat();
        }
        setAppError(
            'Sorry, the consultation can not be started without camera and microphone.'
        );
    };

    requestConsultationWithType(consultationType) {
        this.setState({consultationType}, () => this.requestConsultation());
    }

    openChat(department) {
        if (department && !this.isDepartmentAvailable(department.id)) {
            this.toSendingPhoneNumber();
            return;
        }
        const chatDepartmentId = department
            ? department.id
            : NO_QR_CODE_DEPARTMENTS_CHOSEN;
        this.setState({
            chatState: 'opened',
            consultationState: 'chat',
            chatDepartmentId,
        });
    }

    openAIChat() {
        this.setState({
            chatState: 'opened',
            consultationState: 'chatai',
            chatDepartmentId: NO_QR_CODE_DEPARTMENTS_CHOSEN,
        });
    }

    isDepartmentAvailable(departmentId) {
        const {departments} = this.props.departmentsState;
        const department = departments.find(d => d.id === departmentId);
        return department.hasAvailableMembers;
    }

    backFromChat() {
        const {departments} = this.props.departmentsState;
        if (this.isDepartmentsEnabled() && departments.length > 1) {
            this.setState({chatState: 'chooseDepartments'});
            return;
        }

        this.closeChat();
    }

    backFromAIChat() {
        this.closeChat();
    }

    closeChat() {
        const {stateBeforeChatOpened} = this.state;

        this.setState({
            consultationState: stateBeforeChatOpened,
            chatState: 'initial',
        });
    }

    shouldChooseDepartments() {
        const {departments} = this.props.departmentsState;
        return departments.length !== 0;
    }

    isDepartmentsEnabled() {
        return this.props.departmentsState.allowMultipleTapMeButtons;
    }

    isOnlyOneDepartment() {
        return this.props.departmentsState.departments.length === 1;
    }

    hasAvailableDepartments() {
        return this.props.departmentsState.hasAvailableDepartments;
    }

    isRoomAvailable() {
        const {departments, allowMultipleTapMeButtons} =
            this.props.departmentsState;
        const {room} = this.props.screenState;
        return (
            room.hasAvailableMembers ||
            (allowMultipleTapMeButtons && departments.length)
        );
    }

    toChooseDepartmentsOrCancel() {
        const {chatState} = this.state;
        if (chatState === 'chooseDepartments') {
            this.setState({
                consultationRequestState: 'chooseDepartments',
                phoneNumberSettings: {},
            });
            return;
        }
        if (chatState === 'opened') {
            this.setState({consultationState: 'chat', phoneNumberSettings: {}});
            return;
        }

        if (
            this.shouldChooseDepartments() &&
            !this.isOnlyOneDepartment() &&
            this.isDepartmentsEnabled()
        ) {
            this.setState({
                consultationRequestState: 'chooseDepartments',
                phoneNumberSettings: {},
            });
            return;
        }

        this.toStartState();
    }

    requestPhoneConsultation(phone) {
        IOController.socket.emit('room_webhookTicket', {});

        if (!this.isMobile) {
            return this.setState({phoneConsultationOnPCRequested: true});
        }
        window.location.href = `tel:${getCountryCodeWithoutCountry(
            phone
        )}${getPhoneWithoutCode(phone)}`;
    }

    removePhoneConsultationOnPC() {
        this.setState({phoneConsultationOnPCRequested: false});
    }

    requestConsultation() {
        const {departments} = this.props.departmentsState;

        // if no operators online and no departments in template
        if (!this.isRoomAvailable()) {
            return this.isMiniWebsiteState()
                ? this.toSendingPhoneNumber()
                : this.toStartState();
        }

        // if only one department
        if (this.isDepartmentsEnabled() && this.isOnlyOneDepartment()) {
            return this.hasAvailableDepartments() ||
                (departments[0]?.callToPhone && departments[0]?.phone)
                ? this.sendConsultationRequestSignal({
                      departmentId: departments[0].id,
                  })
                : this.toSendingPhoneNumber({
                      departmentId: departments[0].id,
                  });
        }

        // if more than one department
        if (this.isDepartmentsEnabled() && this.shouldChooseDepartments()) {
            this.setState({consultationRequestState: 'chooseDepartments'});
            return;
        }

        // if no departments and there are operators online
        this.sendConsultationRequestSignal();
    }

    requestConsultationWithDepartmentNumber(number) {
        this.sendConsultationRequestSignal({departmentNumber: number});
    }

    requestConsultationWithDepartmentId(departmentId) {
        this.sendConsultationRequestSignal({departmentId});
    }

    requestConsultationWithDepartmentNumberWithType(number, consultationType) {
        this.setState({consultationType}, () =>
            this.sendConsultationRequestSignal({departmentNumber: number})
        );
    }

    requestChat() {
        const {consultationState} = this.state;
        const {departments} = this.props.departmentsState;
        this.setState({stateBeforeChatOpened: consultationState});

        // if no operators online and no departments in template
        if (!this.isRoomAvailable()) {
            this.toSendingPhoneNumber();
            return;
        }

        // if only one department
        if (this.isDepartmentsEnabled() && this.isOnlyOneDepartment()) {
            this.openChat(departments[0]);
            return;
        }

        // if more than one department
        if (this.isDepartmentsEnabled() && this.shouldChooseDepartments()) {
            this.setState({chatState: 'chooseDepartments'});
            return;
        }

        // if no departments and there are operators online
        this.openChat();
    }

    requestAIChat() {
        const {consultationState} = this.state;
        this.setState({stateBeforeChatOpened: consultationState});
        this.openAIChat();
    }

    requestConsultationFromChat(consultationType) {
        if (!this.isRoomAvailable()) {
            return this.isMiniWebsiteState()
                ? this.toSendingPhoneNumber()
                : this.toStartState();
        }
        const {qrCodeChats, chatDepartmentId} = this.state;
        this.setState({consultationType}, () => {
            const departmentId = this.getChosenQrCodeDepartmentId();
            const qrCodeChatId = qrCodeChats[chatDepartmentId].id;
            this.sendConsultationRequestSignal({departmentId, qrCodeChatId});
        });
    }

    sendConsultationRequestSignalToOnlineMembers() {
        const {requestedDepartmentId, requestedQrCodeChatId} = this.state;
        this.setState({
            requestedDepartmentId: null,
            requestedQrCodeChatId: null,
        });
        this.sendConsultationRequestSignal({
            departmentId: requestedDepartmentId,
            qrCodeChatId: requestedQrCodeChatId,
            requestAvailableOperators: true,
        });
    }

    sendConsultationRequestSignal({
        departmentId = null,
        qrCodeChatId = null,
        requestAvailableOperators = false,
        departmentNumber = null,
    } = {}) {
        const consultationRequestState = {
            consultationRequestProgress: true,
            chatDepartmentId: departmentId || NO_QR_CODE_DEPARTMENTS_CHOSEN,
            departmentChosenForConsultation: departmentId,
        };
        const onConsultationSignalRequestSent = (err, data) => {
            this.setState(prevState => {
                const oldModalsState = {...prevState.modalsState};
                const newState = {
                    modalsState: {
                        ...oldModalsState,
                        terms: {...oldModalsState.terms, isOpened: false},
                        devices: {
                            ...oldModalsState.devices,
                            isOpened: false,
                        },
                    },
                };
                if (data && data.request && data.request.department) {
                    newState.chatDepartmentId = data.request.department;
                }

                return newState;
            });
            if (err) {
                if (err.code === CALL_TO_PHONE) {
                    const requestedUserInfo = err.info ?? {};
                    const requestedUserInfoExists = Object.keys(requestedUserInfo).length > 0;
                    const number = (departmentNumber || requestedUserInfoExists)
                        ? departmentNumber
                        : this.props.departmentsState?.departments?.find(
                              d => d.id === departmentId
                          )?.number;
                    if (!number && !requestedUserInfoExists) {
                        return;
                    }
                    this.onPhoneCallingInit().then(() =>
                        this.callToPhone(number, requestedUserInfo)
                    );
                    return;
                }
                if (err.code === RECONNECTION_ERROR_CODE) {
                    const {setAppError} = this.props.appActions;
                    this.setState({
                        inWaitingToCallState: false,
                    });
                    return setAppError(err.message);
                }
                if (err.code === QR_INTERCOM_DEPARTMENT_NOT_FOUND) {
                    this.setState({
                        inWaitingToCallState: false,
                        qrIntercomNumberNotFound: true,
                        consultationRequestProgress: false,
                    });
                    return;
                }
                if (err.code === REQUESTED_OPERATOR_IS_OFFLINE) {
                    this.setState({
                        inWaitingToCallState: false,
                        consultationRequestState: 'requestedOperatorIsOffline',
                        consultationRequestProgress: false,
                        requestedDepartmentId: departmentId,
                        requestedQrCodeChatId: qrCodeChatId,
                        requestedOperatorName: err.info.operatorName,
                    });
                    return;
                }
                if (err.code === HAS_NO_ADDITIONAL_MINUTES) {
                    this.setState({
                        consultationRequestProgress: false,
                        inWaitingToCallState: false,
                    });
                    this.toSendingPhoneNumber({departmentId});
                    return;
                }
                this.setState({
                    inWaitingToCallState: false,
                    consultationRequestError: true,
                    consultationRequestProgress: false,
                });
                setTimeout(
                    () => this.setState({consultationRequestError: false}),
                    5000
                );
                const {room} = this.props.screenState;
                if (room?.roomInfo?.qr_intercom_screen) {
                    return this.isMiniWebsiteState()
                        ? this.toSendingPhoneNumber({
                              withoutChoosingDepartments: true,
                              departmentId,
                          })
                        : this.toStartState();
                }
                return this.toStartState();
            }
            this.setState({
                inWaitingToCallState: false,
                consultationRequest: {
                    ...data.request,
                    shouldShowQueue: data.shouldShowQueue,
                    shouldAskConfirmation: !this.isMiniWebsiteState(),
                },
                consultationRequestState: 'requestSended',
                consultationRequestProgress: false,
            });
        };
        const onStateChangedToConsultationRequest = () => {
            IOController.socket.emit(
                'room_consultationRequest',
                {
                    departmentId,
                    qrCodeChatId,
                    requestAvailableOperators,
                    departmentNumber,
                },
                onConsultationSignalRequestSent
            );
        };

        this.makePreConsultationChecks(() => {
            this.setState(
                consultationRequestState,
                onStateChangedToConsultationRequest
            );
        });
    }

    onUpdateConsultationRequest = data => {
        const {consultationRequest} = this.state;
        if (!consultationRequest) {
            return;
        }
        if (data.request.positionInQueue === -1) {
            return this.stopConsultationRequest();
        }
        this.setState({
            consultationRequest: {...consultationRequest, ...data.request},
        });
    };

    stopConsultationRequest(forceToStartState = true, consultationRequestRemoved = true) {
        IOController.socket.emit('room_removeConsultationRequest');
        this.setState({
            consultationRequestRemoved,
        });
        if (!forceToStartState) {
            return;
        }
        if (this.props.connected) {
            this.toStartState();
        }
    }

    toTapMeState() {
        const {room} = this.props.screenState;
        const {departments} = this.props.departmentsState;

        const isDepartmentsOnly =
            room.screenSettings.screenTapScreenState === 'DepartmentsOnly';
        const hasDepartments = !!departments.length;
        if (isDepartmentsOnly && hasDepartments) {
            return this.setState({
                consultationState: 'initial',
                consultationRequestState: 'chooseDepartments',
            });
        }
        this.setState({
            consultationState: 'tapme',
            consultationRequestState: 'initial',
        });
    }

    toMiniWebsite() {
        this.setState({
            consultationState: 'miniWebsite',
            consultationRequestState: 'initial',
        });
    }

    toStartState() {
        const {chatState} = this.state;
        const {departments} = this.props.departmentsState;
        if (chatState === 'opened') {
            this.setState({
                consultationState: 'chat',
                consultationRequestState: 'initial',
                phoneNumberSettings: {},
            });
            return;
        }
        if (
            departments &&
            departments.length &&
            !departments.find(d => d.hasAvailableMembers)
        ) {
            // Return chat to initial state from chooseDepartments  state only if departments are offline
            // to prevent going to start state after returning from send phone state.
            this.setState({chatState: 'initial', phoneNumberSettings: {}});
        }
        this.setState({
            requestedDepartmentId: null,
            requestedQrCodeChatId: null,
            phoneNumberSettings: {},
        });
        this.isMiniWebsiteState() ? this.toMiniWebsite() : this.toTapMeState();
    }

    stopAllActivity() {
        const {setAppError} = this.props.appActions;
        const {videoChat} = this.state;
        IOController.socket.emit('room_removeConsultationRequest');
        this.removePrivateChat();
        if (videoChat) {
            videoChat.stop();
        }
        setAppError(
            'Sorry, your link is no longer valid.\n Please request the new link from company employee.'
        );
    }

    getChosenQrCodeDepartmentId() {
        const {chatDepartmentId} = this.state;
        if (chatDepartmentId === NO_QR_CODE_DEPARTMENTS_CHOSEN) {
            return null;
        }
        return chatDepartmentId;
    }

    readQrCodeChat(qrCodeChatId) {
        IOController.socket.emit('qrChat_readChat', {qrCodeChatId}, () => {
            const {qrCodeChats, chatDepartmentId} = this.state;
            this.setState({
                qrCodeChats: {
                    ...qrCodeChats,
                    [chatDepartmentId]: {
                        ...qrCodeChats[chatDepartmentId],
                        wasRead: true,
                    },
                },
            });
        });
    }

    addMessageToChat(message) {
        const {qrCodeChats, chatDepartmentId} = this.state;
        const newQrCodeChats = {...qrCodeChats};
        const messages = [
            ...newQrCodeChats[chatDepartmentId].messages,
            message,
        ];
        const [_, operator] = message.chat.users;
        newQrCodeChats[chatDepartmentId] = {
            ...newQrCodeChats[chatDepartmentId],
            id: message.chat.id,
            assigned: message.chat.users.length === 2,
            messages,
            operator,
        };
        this.setState({qrCodeChats: newQrCodeChats});
    }

    sendQrChatMessage({text, roomId, attachments}, cb) {
        const {qrCodeChats, chatDepartmentId} = this.state;
        const departmentId = this.getChosenQrCodeDepartmentId();
        const qrCodeChatId = _.get(qrCodeChats, `${chatDepartmentId}.id`, null);
        if (qrCodeChatId) {
            IOController.sendStream(
                'qrChat_sendMessage',
                {qrCodeChatId, text, roomId, attachments},
                (err, data) => {
                    if (err) {
                        console.log(err);
                        if (cb) {
                            cb(err);
                        }
                        return;
                    }
                    this.addMessageToChat(data);
                    if (cb) {
                        cb(null, data);
                    }
                }
            );
            return;
        }
        const data = {
            roomId,
            text,
            departmentId,
            attachments,
        };
        IOController.sendStream(
            'qrChat_sendMessageToNewChat',
            data,
            (err, data) => {
                const newQrCodeChats = {...qrCodeChats};
                newQrCodeChats[chatDepartmentId] = {
                    isOperatorTyping: false,
                    wasRead: true,
                    id: data.chat.id,
                    assigned: data.chat.users.length === 2,
                    messages: [data],
                };
                this.setState({qrCodeChats: newQrCodeChats});
                if (cb) {
                    cb(null, data);
                }
            }
        );
    }

    sendQrChatMessageFromConsultation(
        {text, roomId, consultantId, attachments},
        cb
    ) {
        const {qrCodeChats, chatDepartmentId} = this.state;
        const departmentId = this.getChosenQrCodeDepartmentId();
        const qrCodeChatId = _.get(qrCodeChats, `${chatDepartmentId}.id`, null);
        if (qrCodeChatId) {
            IOController.sendStream(
                'qrChat_sendMessage',
                {qrCodeChatId, text, roomId, attachments},
                (err, data) => {
                    if (err) {
                        console.log(err);
                        if (cb) {
                            cb(err);
                        }
                        return;
                    }
                    this.addMessageToChat(data);
                    if (cb) {
                        cb(null, data);
                    }
                }
            );
            return;
        }
        const data = {
            roomId,
            text,
            departmentId,
            consultantId,
            attachments,
        };
        IOController.sendStream(
            'qrChat_sendMessageToNewChatWithConsultant',
            data,
            (err, data) => {
                const newQrCodeChats = {...qrCodeChats};
                const [_, operator] = data.chat.users;
                newQrCodeChats[chatDepartmentId] = {
                    isOperatorTyping: false,
                    wasRead: true,
                    id: data.chat.id,
                    assigned: data.chat.users.length === 2,
                    messages: [data],
                    operator,
                };
                this.setState({qrCodeChats: newQrCodeChats});
                if (cb) {
                    cb(null, data);
                }
            }
        );
    }

    getQrChatMessages(
        messagePage,
        messagesPerPage,
        firstRequestedMessageTimestamp,
        cb
    ) {
        const {qrCodeChats, chatDepartmentId} = this.state;
        this.getChat((err, chat) => {
            if (
                !chat ||
                !chat.messages ||
                chat.messages.length > messagePage * messagesPerPage
            ) {
                return;
            }
            const dataToSend = {
                qrCodeChatId: chat.id,
                messagePage,
                messagesPerPage,
                firstRequestedMessageTimestamp,
            };
            IOController.socket.emit(
                'qrChat_getMessages',
                dataToSend,
                (err, data) => {
                    if (err) {
                        console.log(err);
                        return;
                    }
                    const newChat = {
                        ...chat,
                        messages: [
                            ...data.messages.reverse(),
                            ...chat.messages,
                        ],
                        loadedMessagesCount: data.messagesCount,
                    };
                    if (!newChat.firstRequestedMessageTimestamp) {
                        newChat.firstRequestedMessageTimestamp =
                            firstRequestedMessageTimestamp;
                    }
                    const newChats = {...qrCodeChats};
                    newChats[chatDepartmentId] = newChat;
                    this.setState({qrCodeChats: {...newChats}});
                    if (cb) {
                        cb();
                    }
                }
            );
        });
    }

    getUserChats() {
        const {template} = this.props.templateState;
        if (!template) {
            return;
        }
        IOController.socket.emit('qrChat_getUserChats', {}, (err, data) => {
            if (err) {
                console.log(err);
                return;
            }
            const {chats} = data;
            const {qrCodeChats} = this.state;
            const newQrCodeChats = Object.fromEntries(
                chats.map(c => {
                    const assigned = c.users.length > 1;
                    const [_, operator] = c.users;
                    const newChatDepartment =
                        c.department || NO_QR_CODE_DEPARTMENTS_CHOSEN;
                    const oldChat = qrCodeChats[newChatDepartment];
                    const messages = oldChat ? oldChat.messages : [];
                    return [
                        c.department || NO_QR_CODE_DEPARTMENTS_CHOSEN,
                        {
                            id: c.id,
                            messages,
                            assigned,
                            operator,
                            wasRead: c.wasRead,
                        },
                    ];
                })
            );
            this.setState({qrCodeChats: newQrCodeChats});
        });
    }

    getChat(cb) {
        const {qrCodeChats, chatDepartmentId} = this.state;
        const chat = qrCodeChats[chatDepartmentId];
        if (chat) {
            if (cb) {
                cb(null, chat);
            }
            return;
        }
        const departmentId = this.getChosenQrCodeDepartmentId();
        IOController.socket.emit(
            'qrChat_getUserChat',
            {departmentId},
            (err, data) => {
                if (err) {
                    console.log(err);
                    if (cb) {
                        cb(err, null);
                    }
                    return;
                }
                if (data.chat) {
                    const newChats = {...qrCodeChats};
                    const {chat} = data;
                    const operator = chat.users[1];
                    const newChat = {
                        id: chat.id,
                        assigned: chat.users.length > 1,
                        messages: [],
                        operator,
                    };
                    newChats[
                        _.get(data, 'chat.department.id') ||
                            NO_QR_CODE_DEPARTMENTS_CHOSEN
                    ] = {...newChat};
                    this.setState({qrCodeChats: newChats});
                    if (cb) {
                        cb(null, newChat);
                    }
                    return;
                }
                if (cb) {
                    cb(null, null);
                }
            }
        );
    }

    sendFeedback(data) {
        const {privateRoomId} = this.state;

        IOController.socket.emit('feedback_add', {...data, privateRoomId}, () =>
            this.stopConsultation()
        );
    }

    removePrivateChat(cb) {
        const {privateRoomId} = this.state;
        this.setState({consultationRequestRemoved: false});
        if (!privateRoomId) {
            return;
        }
        IOController.socket.emit('room_removePrivateChat', {
            roomId: privateRoomId,
        });
        cb && cb(privateRoomId);
    }

    toSendingPhoneNumber(phoneNumberSettings = {}) {
        const {
            departmentChosenForConsultation,
            chatDepartmentId,
            consultationRequest,
        } = this.state;
        if (!phoneNumberSettings.departmentId) {
            const departmentId =
                departmentChosenForConsultation ??
                chatDepartmentId ??
                consultationRequest?.department;
            if (departmentId) {
                phoneNumberSettings.departmentId = departmentId;
            }
        }
        this.setState({
            consultationState: 'phoneNumber',
            consultationRequestState: 'initial',
            phoneNumberSettings,
        });
    }

    sendCallRequest(data) {
        IOController.socket.emit('callRequest_add', {...data}, (err, data) => {
            this.setState({didSendPhoneNumber: true});
        });
    }

    toggleVoice() {
        const {videoChat, recognitionEnabled} = this.state;
        if (videoChat) {
            const isVoiceEnabled = videoChat.toggleVoice();
            if (!recognitionEnabled) {
                return;
            }
            if (isVoiceEnabled) {
                this.unpauseRecognition();
            } else {
                this.pauseRecognition();
            }
        }
    }

    toggleVideo() {
        const {videoChat, consultant} = this.state;
        if (videoChat) {
            IOController.socket.emit('videoChat_sendVideoState_p2p', {
                videoEnabled: videoChat.toggleVideo(),
                userId: consultant?.id,
            });
        }
    }

    switchCamera() {
        const {videoChat} = this.state;
        if (videoChat) {
            videoChat.switchCamera();
        }
    }

    async closeConsultation() {
        const closed = await this.onPhoneCallingDestroy(true);
        this.removePrivateChat(roomId => {
            console.log({roomId}, 1);
            this.stopConsultationOrOpenSurveyRateChat(null, roomId);
            if (closed) {
                this.clearConsultationState();
                return this.toStartState();
            }
        });
    }

    onDeleteQrCode = () => {
        this.stopAllActivity();
    };

    handleSnapshotReceived = data => {
        const {dataURL: url} = data;
        if (!url) {
            console.error('empty snapshot received');
            return;
        }
        this.setState({
            snapshot: {url},
            snapshotDrawings: {},
            isSnapshotShown: true,
        });
    };

    handleSnaphotDrawUpdate = ({dataURL: url}) => {
        if (!url) {
            console.error('empty snapshot draw received');
            return;
        }
        this.setState({
            snapshotDrawings: {url: url},
        });
    };

    handleSnapshotInfo = info => {
        this.setState({snapshotSize: info});
    };

    createSnapshot({dataURL, info, name}, cb) {
        if (!dataURL) {
            console.log('SCREEN HAS NO DATA TO CAPTURE!');
            return;
        }

        this.setState({
            snapshot: {isLocal: true, url: dataURL},
            snapshotDrawings: {},
            isSnapshotShown: true,
        });

        if (
            !_.get(
                this,
                'state.videoChat.consultationDataChannel.sendDataURLByChunks'
            )
        ) {
            console.error('no data chanel');
            return;
        }

        const data = {
            event: 'snapshot_created',
            dataURL,
        };

        this.state.videoChat.consultationDataChannel.send({
            event: 'snapshot_create_info',
            info,
        });
        this.state.videoChat.consultationDataChannel.sendDataURLByChunks(data);
    }

    handleLocalSnapshotClose() {
        const data = {
            event: 'snapshot_close',
        };
        this.state.videoChat.consultationDataChannel.send(data);
        this.setState(SNAPSHOT_DEFAULT_STATE);
    }

    makePreConsultationChecks(onConfirm) {
        const isTermsEnabledInSubscription =
            this.props.screenState.room.roomInfo.qr_terms;
        const isTermsConfirmed = this.state.modalsState.terms.isConfirmed;
        const isDevicesEnabledInSubscription =
            this.props.screenState.room.roomInfo.qr_devices_check;
        const isDevicesConfirmed = this.state.modalsState.devices.isConfirmed;
        const needsToAcceptTerms =
            isTermsEnabledInSubscription && !isTermsConfirmed;
        const needsToCheckDevices =
            isDevicesEnabledInSubscription && !isDevicesConfirmed;

        const onConfirmDevices = () => {
            onConfirm();

            this.setState(prevState => {
                return {
                    consultationRequestRemoved: false,
                    modalsState: {
                        ...prevState.modalsState,
                        devices: {
                            isOpened: prevState.modalsState.devices.isOpened,
                            isConfirmed: true,
                        },
                        onConfirm: null,
                    },
                };
            });
        };

        const onAcceptTerms = () => {
            if (!needsToCheckDevices) {
                onConfirm();
            }

            this.setState(prevState => {
                const shouldTermsBeOpened = needsToCheckDevices
                    ? false
                    : prevState.modalsState.terms.isOpened;
                const terms = {
                    isOpened: shouldTermsBeOpened,
                    isConfirmed: true,
                };
                const devices = needsToCheckDevices
                    ? {isOpened: true, isConfirmed: false}
                    : prevState.modalsState.devices;
                const onConfirm = needsToCheckDevices ? onConfirmDevices : null;

                return {modalsState: {terms, devices, onConfirm}};
            });
        };

        if (needsToAcceptTerms) {
            this.setState(prevState => ({
                modalsState: {
                    ...prevState.modalsState,
                    terms: {isOpened: true, isConfirmed: false},
                    onConfirm: onAcceptTerms,
                },
            }));
            return;
        }

        if (needsToCheckDevices) {
            this.setState(prevState => ({
                modalsState: {
                    ...prevState.modalsState,
                    devices: {isOpened: true, isConfirmed: false},
                    onConfirm: onConfirmDevices,
                },
            }));
            return;
        }

        onConfirm();
    }

    closeTermsModal() {
        this.setState(prevState => ({
            modalsState: {
                ...prevState.modalsState,
                terms: {isOpened: false, isConfirmed: false},
                onConfirm: null,
            },
        }));
    }

    closeDevicesModal() {
        this.setState(prevState => ({
            modalsState: {
                ...prevState.modalsState,
                devices: {isOpened: false, isConfirmed: false},
                onConfirm: null,
            },
        }));
    }

    removeQrIntercomNumberNotFoundState() {
        this.setState({qrIntercomNumberNotFound: false});
    }

    setPhraseDeletionTimeout = () => {
        this.participantPhrases.clearByTime();
        this.setState(
            {recognitionPhrases: this.participantPhrases.getPhrases()},
            () =>
                (this.phraseDeletionTimeout = setTimeout(
                    this.setPhraseDeletionTimeout,
                    1000
                ))
        );
    };

    onPhrase = (type, offset, text) => {
        this.participantPhrases.add({type, offset, text});
        this.setState({
            recognitionPhrases: this.participantPhrases.getPhrases(),
        });
    };

    onOperatorPhrase = ({offset, text}) =>
        this.onPhrase('operator', offset, text);

    onScreenPhrase = (offset, text) => {
        this.onPhrase('screen', offset, text);
        IOController.socket.emit('speechRecognition_sendText', {offset, text});
    };

    onStartRecognition = ({operatorLanguage, screenLanguage}) => {
        const {localStream} = this.state;
        const onStart = () => {
            IOController.socket.emit('azureStatistic_writeStatistic', {
                type: 'start',
                recognizerType: 'translation',
            });
        };
        const onError = e => console.error(e);
        AzureSpeechRecognizer.startTranslationRecognition(
            this.props.screenState.room.roomInfo.screen_azure_env,
            onStart,
            this.onScreenPhrase,
            onError,
            screenLanguage,
            operatorLanguage,
            localStream ? localStream.clone() : null
        );

        this.setState({
            recognitionEnabled: true,
            recognitionLanguages: {operatorLanguage, screenLanguage},
        });
    };

    onStopRecognition = () => {
        AzureSpeechRecognizer.stopTranslationRecognition(
            (workedBefore = true) => {
                if (!workedBefore) {
                    return;
                }
                IOController.socket.emit('azureStatistic_writeStatistic', {
                    type: 'end',
                    recognizerType: 'translation',
                });
            }
        );

        this.setState({
            recognitionEnabled: false,
            recognitionLanguages: {
                operatorLanguage: null,
                screenLanguage: null,
            },
        });
    };

    pauseRecognition = () => {
        AzureSpeechRecognizer.stopTranslationRecognition(
            (workedBefore = true) => {
                if (!workedBefore) {
                    return;
                }
                IOController.socket.emit('azureStatistic_writeStatistic', {
                    type: 'end',
                    recognizerType: 'translation',
                });
            }
        );
    };

    unpauseRecognition = () => {
        const {recognitionLanguages} = this.state;
        const {operatorLanguage, screenLanguage} = recognitionLanguages;
        this.onStartRecognition({operatorLanguage, screenLanguage});
    };

    onPhoneCallingInit() {
        if (twilioDevice) {
            return Promise.resolve();
        }
        return new Promise((resolve, reject) => {
            IOController.socket.emit('twilio_getToken', {}, (err, token) => {
                if (err) {
                    reject(err);
                    return;
                }
                twilioDevice = new Device(token, {});
                this.setState({isTwilioDeviceInitiated: true}, resolve);
            });
        });
    }

    async onPhoneCallingDestroy(shouldCancelCall = false) {
        const closed = await this.closePhoneCall(shouldCancelCall);
        if (!closed) {
            return false;
        }
        IOController.socket.emit('room_updateBusyState', {
            busyState: false,
        });
        if (!twilioDevice) {
            return true;
        }
        twilioDevice.destroy();
        twilioDevice = null;
        return true;
    }

    async callToPhone(phoneNumber, requestedUserInfo) {
        const {
            templateState,
            screenState: {user},
        } = this.props;
        if (twilioCall) {
            twilioCall.disconnect();
        }
        const {isTwilioDeviceInitiated} = this.state;
        if (!twilioDevice || !isTwilioDeviceInitiated) {
            return;
        }
        this.setState({
            inWaitingToCallState: false,
            consultationRequest: {
                shouldShowQueue: false,
                shouldAskConfirmation: !this.isMiniWebsiteState(),
            },
            consultationRequestState: 'requestSended',
            consultationRequestProgress: false,
        });
        const params = {
            userId: user.id,
            doorUnlockOption: templateState?.template?.doorUnlockOption,
            companyId: templateState?.template?.companyId,
            ...(requestedUserInfo ?? {}),
        };
        if (phoneNumber) {
            params.phone = phoneNumber;
        }
        if (templateState?.template?.doorUnlockOption === 'webhook') {
            params.doorUnlockUrl = templateState.template.doorUnlockUrl;
        }
        IOController.socket.emit('room_updateBusyState', {
            busyState: true,
        });
        twilioCall = await twilioDevice.connect({
            params,
        });
        twilioCall.once('accept', conn => {
            console.log('accepted');
            this.setState({
                consultationType: 'phone',
                privateChatRequest: {},
                consultant: {
                    userInfo: {
                        first_name: '',
                        last_name: '',
                    },
                },
                requested: true,
                privateRoomId: '1',
                consultationRequest: null,
                consultationRequestState: 'initial',
                consultationState: 'started',
            });
        });
        twilioCall.once('pending', conn => {
            console.log('pending');
        });
        twilioCall.on('cancel', conn => {
            console.log('canceled');
            this.onPhoneCallingDestroy();
            this.toStartState();
        });
        twilioCall.on('disconnect', conn => {
            console.log('disconnected');
            this.onPhoneCallingDestroy();
            this.toStartState();
        });
        twilioCall.on('error', err => {
            console.log(err);
            console.log('error');
            this.onPhoneCallingDestroy();
            this.toStartState();
        });
        twilioCall.on('reject', err => {
            console.log('rejected');
            this.onPhoneCallingDestroy();
            this.toStartState();
        });
    }

    async closePhoneCall(shouldCancelCall = false) {
        const {user} = this.props.screenState;
        if (!twilioCall) {
            return false;
        }
        twilioCall.removeAllListeners();
        twilioCall.disconnect();
        twilioCall = null;
        if (!shouldCancelCall) {
            try {
                await fetch(`${process.env.SIGNAL_HTTP_URL}/api/sip/endCall`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        userId: user.id,
                    }),
                });
            } catch (err) {
                console.log(err);
            } finally {
                return true;
            }
        }
        try {
            await fetch(`${process.env.SIGNAL_HTTP_URL}/api/sip/cancelCall`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: user.id,
                }),
            });
        } catch (err) {
            console.log(err);
        } finally {
            return true;
        }
    }

    async onPhotoDone(data) {
        const {
            screenState,
            screenState: {user, room},
        } = this.props;
        const formData = new FormData();
        formData.set('userId', user.id);
        formData.set(
            'address',
            _.get(room, 'roomInfo.company_name', 'Unknown')
        );
        formData.set('snapshot', data);
        await fetch(`${process.env.SIGNAL_HTTP_URL}/intercomSnapshots`, {
            mode: 'no-cors',
            method: 'POST',
            body: formData,
        });
    }

    async setInWaitingCallState() {
        this.setState({inWaitingToCallState: true});
    }

    render() {
        return (
            <ConsultationContext.Provider
                value={{state: this.state, actions: this.actions}}
            >
                {this.props.children}
            </ConsultationContext.Provider>
        );
    }
}

export const ConsultationProvider = props => (
    <AppConsumer>
        {({state: appState, actions: appActions}) => (
            <ScreenConsumer>
                {({state: screenState, actions: screenAction}) => (
                    <TemplateConsumer>
                        {({state: templateState}) => (
                            <DepartmentsConsumer>
                                {({state, actions}) => (
                                    <ConsultationContextProvider
                                        appState={appState}
                                        appActions={appActions}
                                        screenState={screenState}
                                        screenActions={screenAction}
                                        departmentsState={state}
                                        departmentsActions={actions}
                                        isUserRegistered={
                                            screenState.isUserRegistered
                                        }
                                        templateState={templateState}
                                        {...props}
                                    />
                                )}
                            </DepartmentsConsumer>
                        )}
                    </TemplateConsumer>
                )}
            </ScreenConsumer>
        )}
    </AppConsumer>
);

export const ConsultationConsumer = ConsultationContext.Consumer;
